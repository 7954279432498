import React from "react"
import { isLoggedIn } from "../../services/auth"
import Login from "../../components/bonus/login"
import Layout from "../../components/bonus/layout"
import { Container, Row, Col, Card } from "react-bootstrap"
import Wistia from "../../components/bonus/zmfersxw"
import Countdown from '../Cdbonus';

export default function Home() {
    return (
        <Layout>
            {isLoggedIn() ? (
                <>
                    <Container>
                        <Row className="justify-content-center height-align-center">
                            <Col lg={9}>
                                <Card className="mb-3">
                                    <Card.Body>
                                        <Row>
                                            <Col lg={5}>
                                                <p className="text-cd-bonus">Waktumu Untuk Akses Video Tersisa</p>
                                            </Col>
                                            <Col lg={7}>
                                                <Countdown date={`September 27, 2021 08:00:00`} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className="mb-4 text-title-bonus-hlg">"Avatar Secret" (Cara Kilat Bikin Kriteria Pria Tepat)</Card.Title>
                                        <Wistia />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            ) : (
                <>
                    <Login />
                </>
            )}
        </Layout>
    )
}