import React from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../../services/auth"
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap"

class Login extends React.Component {
    state = {
        username: ``,
        password: ``,
    }

    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = event => {
        event.preventDefault()
        handleLogin(this.state)
    }

    render() {
        if (isLoggedIn()) {
            navigate(`/bonus/`)
        }

        return (
            <>

                <Container>
                    <Row className="justify-content-center height-align-center">
                        <Col lg={6}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        <b>Form Login</b>
                                    </Card.Title>
                                    <Form method="post" onSubmit={event => {
                                        this.handleSubmit(event)
                                        navigate(`/bonus/`)
                                    }}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control type="text" name="username" onChange={this.handleUpdate} required />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" name="password" onChange={this.handleUpdate} required />
                                        </Form.Group>
                                        <Button variant="success" type="submit" className="btn-block">
                                            Log In
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Login