import React, { Component } from 'react';

class WistiaEmbed extends Component {
    constructor(props) {
        super(props);
        const { ...embedOptions } = { ...this.props };
        if (typeof window !== `undefined`) {
            window._wq = window._wq || [];
            window._wq.push({
                id: '7tcn377hux',
                options: embedOptions,
                onHasData: (video) => {
                    this.handle = video;
                },
            });
        }
    }

    _renderCommon() {
        return (
            <div
                class="wistia_swatch"
                style={{
                    height: '100%',
                    left: 0,
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    top: 0,
                    transition: 'opacity 200ms',
                    width: '100%',
                }}
            >
                <img
                    src={`https://fast.wistia.com/embed/medias/7tcn377hux/swatch`}
                    style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }}
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                />
            </div>
        );
    }

    _renderResponsive() {
        const { padding } = this.props;

        return (
            <div className="wistia_responsive_padding" style={{ padding, position: 'relative' }}>
                <div
                    className="wistia_responsive_wrapper"
                    style={{ height: '100%', left: '0', position: 'relative', top: 0, width: '100%' }}
                >
                    <div
                        className={`wistia_embed wistia_async_7tcn377hux videoFoam=true`}
                        style={{ height: '100%', width: '100%', position: 'relative' }}
                    >
                        {this._renderCommon()}
                    </div>
                </div>
            </div>
        );
    }

    _renderFixed() {
        const { width, height } = this.props;
        return (
            <div
                className={`wistia_embed wistia_async_7tcn377hux`}
                style={`height:${height || 480}px;position:relative;width:${width || 640}px`}
            >
                {this._renderCommon()}
            </div>
        );
    }

    render() {
        const { isResponsive } = this.props;
        return isResponsive ? this._renderResponsive() : this._renderFixed;
    }

    componentDidMount() {
        if (!document.getElementById('wistia_script')) {
            var wistiaScript = document.createElement('script');
            wistiaScript.id = 'wistia_script';
            wistiaScript.type = 'text/javascript';
            wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
            wistiaScript.async = true;
            document.body.appendChild(wistiaScript);
        }
    }

    componentWillUnmount() {
        this.handle && this.handle.remove();
    }
}

WistiaEmbed.defaultProps = {
    isResponsive: true,
};

export default WistiaEmbed;