import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn, logout } from "../../services/auth"
import { Container, Navbar, Nav } from "react-bootstrap"

export default function NavBar() {
    return (
        <>
            <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand href="#">
                        Bonus Happy Love Guide
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav className="me-auto">
                            {isLoggedIn() ? (
                                <Nav.Link href="/" onClick={event => {
                                    event.preventDefault()
                                    logout(() => navigate(`/bonus/`))
                                }}>Logout</Nav.Link>
                            ) : null}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}